import {
  Routes,
  Route,
  Navigate,
  BrowserRouter as Router,
} from "react-router-dom";
import React from "react";

import Apb from "./views/pages/apb";
// import Identification from "./views/pages";
import ApbCertificate from "./views/pages/ApbCertificate";

// import Riskcovry from "./views/pages/riskcovry";

// import DmiFinance from "./views/pages/medicalmobile";

import StatusPage from "./views/statuspage";

// import LandingPage from "./views/pages/dimsmobile/LandingPage";
// import ProtectForm from "./views/pages/dimsmobile/ProtectForm";
// import PaymentLoading from "./views/pages/dimsmobile/PaymentLoading";
// import PaymentSuccess from "./views/pages/dimsmobile/PaymentSuccess";
// import PaymentFailure from "./views/pages/dimsmobile/PaymentFailure";

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route exact path="/" element={<Identification />} /> */}

        {/* ****** Apb Page ****** */}
        <Route exact path="/" element={<Apb />} />
        {/* <Route exact path="/apb-certificate" element={<ApbCertificate />} /> */}

        {/* ****** Upload Successful Page ****** */}
        <Route path="/statusPage" element={<StatusPage />} />

        {/* ****** Riskcovry Page ****** */}
        {/* <Route exact path="/" element={<Riskcovry />} /> */}

        {/* ****** DMI Mobile Page ****** */}
        {/* <Route path="/" element={<LandingPage />} />
        <Route path="/protect-form" element={<ProtectForm />} />
        <Route path="/payment-process" element={<PaymentLoading />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/payment-failure" element={<PaymentFailure />} /> */}

        {/* ****** DMI Finance Page ****** */}
        {/* <Route exact path="/" element={<DmiFinance />} /> */}

        {/* <Route path="/payment-success" element={<PaymentSuccessPage />} /> */}

        {/* When we enter wrong route */}
        <Route path="*" element={<Navigate to="/" />} />
        {/* <Route path="*" element={<Navigate to="/apb-certificate" />} /> */}
      </Routes>
    </Router>
  );
}

export default App;

// ****** Working --> Imei Detect With Photo ******
// import React, { useRef, useState } from "react";
// import Tesseract from "tesseract.js";

// const IMEIDetector = () => {
//   const videoRef = useRef(null);
//   const canvasRef = useRef(null);
//   const [imei, setImei] = useState("");

//   // Start camera
//   const startCamera = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({
//         video: { facingMode: "environment" }, // Use back camera
//       });
//       if (videoRef.current) {
//         videoRef.current.srcObject = stream;
//         videoRef.current.play();
//       }
//     } catch (error) {
//       console.error("Error accessing camera:", error);
//     }
//   };

//   // Capture frame and detect IMEI
//   const captureFrameAndDetectIMEI = async () => {
//     const video = videoRef.current;
//     const canvas = canvasRef.current;

//     if (video && canvas) {
//       const context = canvas.getContext("2d");
//       canvas.width = video.videoWidth;
//       canvas.height = video.videoHeight;

//       // Draw video frame onto canvas
//       context.drawImage(video, 0, 0, canvas.width, canvas.height);

//       // Extract text using Tesseract.js
//       const imageData = canvas.toDataURL("image/png");
//       Tesseract.recognize(imageData, "eng", {
//         logger: (info) => console.log(info), // Progress logging
//       }).then(({ data: { text } }) => {
//         console.log("Detected Text:", text);

//         // Validate and extract IMEI (15-digit numeric)
//         const imeiMatch = text.match(/\b\d{15}\b/);
//         if (imeiMatch) {
//           setImei(imeiMatch[0]);
//         } else {
//           alert("No valid IMEI found. Please try again.");
//         }
//       });
//     }
//   };

//   return (
//     <div>
//       <h1>IMEI Detector</h1>
//       <video ref={videoRef} style={{ width: "100%" }} />
//       <canvas ref={canvasRef} style={{ display: "none" }} />
//       <button onClick={startCamera}>Start Camera</button>
//       <button onClick={captureFrameAndDetectIMEI}>Detect IMEI</button>
//       {imei && <h2>Detected IMEI: {imei}</h2>}
//     </div>
//   );
// };

// export default IMEIDetector;

// ****** Testing 1 --> Imei Detect With Video ******
// import React, { useRef, useState } from "react";
// import Tesseract from "tesseract.js";

// const IMEIDetector = () => {
//   const videoRef = useRef(null);
//   const canvasRef = useRef(null);
//   const [imei, setImei] = useState("");
//   const [isRecording, setIsRecording] = useState(false);
//   const mediaRecorderRef = useRef(null);
//   const [recordedChunks, setRecordedChunks] = useState([]);

//   // Start camera
//   const startCamera = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({
//         video: { facingMode: "environment" }, // Use back camera
//       });
//       if (videoRef.current) {
//         videoRef.current.srcObject = stream;
//         videoRef.current.play();
//         startRecording(stream);
//       }
//     } catch (error) {
//       console.error("Error accessing camera:", error);
//     }
//   };

//   // Start recording the video
//   const startRecording = (stream) => {
//     const mediaRecorder = new MediaRecorder(stream);
//     mediaRecorderRef.current = mediaRecorder;
//     const chunks = [];
//     mediaRecorder.ondataavailable = (e) => chunks.push(e.data);
//     mediaRecorder.onstop = () => {
//       setRecordedChunks(chunks);
//       // Optionally, you can save the recorded video or process the chunks further
//     };
//     mediaRecorder.start();
//     setIsRecording(true);
//   };

//   // Stop recording the video
//   const stopRecording = () => {
//     if (
//       mediaRecorderRef.current &&
//       mediaRecorderRef.current.state !== "inactive"
//     ) {
//       mediaRecorderRef.current.stop();
//       setIsRecording(false);
//     }
//   };

//   // Capture frame from video stream and detect IMEI
//   const captureFrameAndDetectIMEI = async () => {
//     const video = videoRef.current;
//     const canvas = canvasRef.current;

//     if (video && canvas) {
//       const context = canvas.getContext("2d");
//       canvas.width = video.videoWidth;
//       canvas.height = video.videoHeight;

//       // Draw video frame onto canvas
//       context.drawImage(video, 0, 0, canvas.width, canvas.height);

//       // Extract text using Tesseract.js
//       const imageData = canvas.toDataURL("image/png");
//       Tesseract.recognize(imageData, "eng", {
//         logger: (info) => console.log(info), // Progress logging
//       }).then(({ data: { text } }) => {
//         console.log("Detected Text:", text);

//         // Validate and extract IMEI (15-digit numeric)
//         const imeiMatch = text.match(/\b\d{15}\b/);
//         if (imeiMatch) {
//           setImei(imeiMatch[0]);
//         } else {
//           setImei("No IMEI detected");
//         }
//       });
//     }
//   };

//   return (
//     <div>
//       <h1>IMEI Detector</h1>
//       <video ref={videoRef} style={{ width: "400px", height: "400px" }} />
//       <canvas ref={canvasRef} style={{ display: "none" }} />
//       {!isRecording ? (
//         <button onClick={startCamera}>Start Camera and Recording</button>
//       ) : (
//         <button onClick={stopRecording}>Stop Recording</button>
//       )}
//       <button onClick={captureFrameAndDetectIMEI}>
//         Capture Frame and Detect IMEI
//       </button>
//       {imei && <h2>Detected IMEI: {imei}</h2>}
//     </div>
//   );
// };

// export default IMEIDetector;

// ****** Testing 2 --> Imei Detect With Video & Video Preview ******
// import React, { useRef, useState } from "react";
// import Tesseract from "tesseract.js";

// const IMEIDetector = () => {
//   const videoRef = useRef(null);
//   const canvasRef = useRef(null);
//   const [imei, setImei] = useState("");
//   const [isRecording, setIsRecording] = useState(false);
//   const mediaRecorderRef = useRef(null);
//   const [recordedChunks, setRecordedChunks] = useState([]);
//   const [videoURL, setVideoURL] = useState("");

//   // Start camera
//   const startCamera = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({
//         video: { facingMode: "environment" }, // Use back camera
//       });
//       if (videoRef.current) {
//         videoRef.current.srcObject = stream;
//         videoRef.current.play();
//         startRecording(stream);
//       }
//     } catch (error) {
//       console.error("Error accessing camera:", error);
//     }
//   };

//   // Start recording the video
//   const startRecording = (stream) => {
//     const mediaRecorder = new MediaRecorder(stream);
//     mediaRecorderRef.current = mediaRecorder;
//     const chunks = [];
//     mediaRecorder.ondataavailable = (e) => chunks.push(e.data);
//     mediaRecorder.onstop = () => {
//       setRecordedChunks(chunks);
//       // Create a Blob from the recorded chunks and generate a URL to preview the video
//       const blob = new Blob(chunks, { type: "video/webm" });
//       const videoUrl = URL.createObjectURL(blob);
//       setVideoURL(videoUrl);
//     };
//     mediaRecorder.start();
//     setIsRecording(true);
//   };

//   // Stop recording the video
//   const stopRecording = () => {
//     if (
//       mediaRecorderRef.current &&
//       mediaRecorderRef.current.state !== "inactive"
//     ) {
//       mediaRecorderRef.current.stop();
//       setIsRecording(false);
//     }
//   };

//   // Capture frame from video stream and detect IMEI
//   const captureFrameAndDetectIMEI = async () => {
//     const video = videoRef.current;
//     const canvas = canvasRef.current;

//     if (video && canvas) {
//       const context = canvas.getContext("2d");
//       canvas.width = video.videoWidth;
//       canvas.height = video.videoHeight;

//       // Draw video frame onto canvas
//       context.drawImage(video, 0, 0, canvas.width, canvas.height);

//       // Extract text using Tesseract.js
//       const imageData = canvas.toDataURL("image/png");
//       Tesseract.recognize(imageData, "eng", {
//         logger: (info) => console.log(info), // Progress logging
//       }).then(({ data: { text } }) => {
//         console.log("Detected Text:", text);

//         // Validate and extract IMEI (15-digit numeric)
//         const imeiMatch = text.match(/\b\d{15}\b/);
//         if (imeiMatch) {
//           setImei(imeiMatch[0]);
//         } else {
//           setImei("No IMEI detected");
//         }
//       });
//     }
//   };

//   return (
//     <div>
//       <h1>IMEI Detector</h1>
//       <video ref={videoRef} style={{ width: "400px", height: "400px" }} />
//       <canvas ref={canvasRef} style={{ display: "none" }} />
//       {!isRecording ? (
//         <button onClick={startCamera}>Start Camera and Recording</button>
//       ) : (
//         <button onClick={stopRecording}>Stop Recording</button>
//       )}
//       <button onClick={captureFrameAndDetectIMEI}>
//         Capture Frame and Detect IMEI
//       </button>
//       {imei && <h2>Detected IMEI: {imei}</h2>}

//       {videoURL && (
//         <div>
//           <h2>Preview Recorded Video:</h2>
//           <video src={videoURL} controls width="100%" />
//           <a href={videoURL} download="recorded-video.webm">
//             <button>Download Video</button>
//           </a>
//         </div>
//       )}
//     </div>
//   );
// };

// export default IMEIDetector;
